import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';

import { closeModal, showModal } from '../../actions/defaultActions';
import { setEstadoInicialUsuarioLogado } from '../../actions/usuarioActions';
import urlConsts from '../../helpers/consts/urlConsts';
import miniLogo from '../assets/images/navigation/nav-header-mini.png';
import DrawerEmpresa from '../components/drawerEmpresa';
import MenuItem from '../menuItem';
import MenuTree from '../menuTree';

export class Navigation extends Component {
  componentDidUpdate() {
    const { menu } = this.refs;
    if (this.props.rota.menus.length > 0) {
      $(function () {
        $(menu).metisMenu({
          toggle: true,
        });
      });
    }
  }

  componentWillUnmount() {
    this.props.setEstadoInicialUsuarioLogado();
  }

  renderMenuTree(menu) {
    return (
      <MenuTree key={menu.funcaoId} icon={menu.icone} label={menu.funcaoDescricao}>
        {menu.menus.map(item => {
          if (item.menus && item.menus.length > 0) {
            this.renderMenuTree(item);
          } else {
            return this.renderMenuItemTree(item);
          }
        })}
      </MenuTree>
    );
  }

  renderMenuItemTree(menu) {
    return (
      <MenuItem
        key={menu.funcaoId}
        path={`#${menu.rota}`}
        icon={menu.icone}
        label={menu.funcaoDescricao}
        tree={true}
      />
    );
  }

  renderMenuItem(menu) {
    return (
      <MenuItem
        key={menu.funcaoId}
        path={`#${menu.rota}`}
        icon={menu.icone}
        label={menu.funcaoDescricao}
      />
    );
  }

  renderNavigation() {
    const { empregado, pessoaLogada } = this.props.usuario;

    const fotoPerfil = pessoaLogada.foto || 'nav-header-mini.png';

    return (
      <Fragment>
        <nav className="navbar-default navbar-static-side" role="navigation">
          <div className="sidebar-collapse">
            <ul className="nav metismenu" id="side-menu" ref="menu">
              <li className="nav-header">
                <div className="row">
                  <div className="dropdown profile-element" style={{ textAlign: 'center' }}>
                    <div className="row">
                      <div className="col-md-3" />
                      <div className="col-md-3">
                        <span>
                          <img
                            alt="profile"
                            className="img-circle img-md img-center"
                            style={{ width: 80, height: 80 }}
                            src={`${process.env.REACT_APP_AZURE_BLOB_STORAGE}/perfil/${fotoPerfil}`}
                          />
                        </span>
                      </div>
                    </div>
                    <span className="clear">
                      <span className="block m-t-xs">
                        <strong className="text-muted text-sm">{pessoaLogada.nome}</strong>
                      </span>
                      <span className="text-muted text-xs block">
                        {empregado && empregado.contrato && empregado.contrato.cargo
                          ? empregado.contrato.cargo.descricao
                          : pessoaLogada.tipo}
                      </span>
                    </span>
                  </div>
                </div>

                <div className="logo-element">
                  <img alt="image" className="img-sm" src={miniLogo} />
                </div>
              </li>
              {/* menu */}
              {this.props.rota.menus.map(menu => {
                if (menu.permitido) {
                  if (menu.menus && menu.menus.length > 0) {
                    return this.renderMenuTree(menu);
                  } else {
                    return this.renderMenuItem(menu);
                  }
                }
              })}
            </ul>
          </div>
        </nav>

        <DrawerEmpresa visible={this.props.default.modalVisible} onCancel={this.props.closeModal} />
      </Fragment>
    );
  }

  render() {
    return <div>{this.renderNavigation()}</div>;
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario,
  rota: state.rota,
  default: state.default,
});
const mapDispatchToProps = { setEstadoInicialUsuarioLogado, showModal, closeModal };

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Navigation);
