import * as a from '../actionTypes/actionTypes'

const INITIAL_STATE = {
    processandoLiquidoFolha: false,
    liquidosFolha: []
}

export default ( state = INITIAL_STATE, action ) =>{

    const { type, payload } = action

    switch (type) {
        case a.LQF_SET_ESTADO_INICIAL:
            return { ...state, processandoLiquidoFolha: false, liquidosFolha: [] }
        case a.LQF_GET_LIQUIDOS_FOLHA:
            return { ...state, liquidosFolha: payload }
        case a.LQF_PROCESSANDO_LIQUIDOS_FOLHA:
            return { ...state, processandoLiquidoFolha: payload }
        default:
            return { ...state };
    }
}
