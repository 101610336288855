import * as a from '../actionTypes/actionTypes'

const INITIAL_STATE = {
    processandoMapaFolha: false,
    mapasFolha: []
}

export default (state = INITIAL_STATE, action) => {

    const { type, payload } = action

    switch (type) {
        case a.MPF_SET_ESTADO_INICIAL:
            return { ...state, processandoMapaFolha: false, mapasFolha: [] }
        case a.MPF_GET_MAPAS_FOLHA:
            return { ...state, mapasFolha: payload }
        case a.MPF_PROCESSANDO_MAPAS_FOLHA:
            return { ...state, processandoMapaFolha: payload }
        default:
            return { ...state };
    }
}
