import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { HashRouter, Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import RouteWithSubRoutes from './routeWithSubRoutes';
import NotFound from "../pages/notFound";
import sentinelaFront from '../util/sentinelaFront';

class Routers extends React.PureComponent {
  static verificaToken(props) {
    const { origin } = window.location;
    const redirectURL = `redirectURL=${origin}&toLocation=${props.location.pathname}`;
    const token = sessionStorage.getItem('token');

    return token ? (
      <NotFound {...props} />
    ) : (
      window.location.assign(
        `${sentinelaFront[process.env.NODE_ENV]}/#/login?${redirectURL}&fromApplication=4`,
      )
    );
  }

  render() {
    return (
      <Router>
        <Suspense fallback={<div>Carregando...</div>}>
          <HashRouter>
            <Switch>
              {this.props.rota.rotas.map((route) => (
                <RouteWithSubRoutes key={route.path} {...route} />
              ))}
              <Route path="*" render={this.verificaToken} />
            </Switch>
          </HashRouter>
        </Suspense>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({ rota: state.rota });

export default connect(mapStateToProps, null, null, { forwardRef: true })(Routers);
