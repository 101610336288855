import {
  AVF_GET_AVISOS_FERIAS,
  AVF_PROCESSANDO_AVISOS_FERIAS,
  AVF_SET_ESTADO_INICIAL,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoAvisoFerias: false,
  avisosFerias: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case AVF_SET_ESTADO_INICIAL:
      return { ...state, processandoAvisoFerias: false, avisosFerias: [] };
    case AVF_GET_AVISOS_FERIAS:
      return { ...state, avisosFerias: payload };
    case AVF_PROCESSANDO_AVISOS_FERIAS:
      return { ...state, processandoAvisoFerias: payload };
    default:
      return { ...state };
  }
};
