import * as a from '../actionTypes/actionTypes'

const INITIAL_STATE = {
    processandoInformeRendimentos: false,
    informesRendimentos: []
}

export default ( state = INITIAL_STATE, action ) =>{

    const { type, payload } = action

    switch (type) {
        case a.INR_SET_ESTADO_INICIAL:
            return { ...state, processandoInformeRendimentos: false, informesRendimentos: [] }
        case a.INR_GET_INFORMES_RENDIMENTOS:
            return { ...state, informesRendimentos: payload }
        case a.INR_PROCESSANDO_INFORMES_RENDIMENTOS:
            return { ...state, processandoInformeRendimentos: payload }
        default:
            return { ...state };
    }
}
