import { toastr } from 'react-redux-toastr';
import Hash from 'react-router-history';

import {
  USR_GET_DADOS_EMPREGADO,
  USR_GET_PESSOA_LOGADA,
  USR_SET_ESTADO_INICIAL_USUARIO_LOGADO,
  USR_SET_PESSOA_JA_LOGADA,
} from '../actionTypes/actionTypes';
import connect from '../api/connect';
import Functions from '../helpers/functions';

const { hashHistory } = Hash;

export const setPessoaLogada = values => ({
  type: USR_GET_PESSOA_LOGADA,
  payload: values,
});

export function setEstadoInicialUsuarioLogado() {
  return {
    type: USR_SET_ESTADO_INICIAL_USUARIO_LOGADO,
  };
}

export const setDadosEmpregado = data => ({
  type: USR_GET_DADOS_EMPREGADO,
  payload: data,
});

export const verificaPessoaLogada = async () => (dispatch, getState) => {
  const { usuario } = getState();

  dispatch({
    type: USR_SET_PESSOA_JA_LOGADA,
    payload: !!usuario.pessoaLogada.id,
  });
};

export const getEmpregadoPessoa = inscricao => async (dispatch) => {
  try {
    const { data } = await connect.get(
      `/empregados?filtro=[{"campo": "inscricao", "valor": "${inscricao}"}]`,
    );

    if (data.length > 0) {
      const empregado = data[0];

      await connect.post(`/empregados/atualizar-situacao-pela-rescisao`, empregado);

      const { data: pessoa } = await connect.get(`/pessoas/${empregado.pessoaId}`);

      if (!pessoa.status) {
        dispatch(getPessoaLogada());
        return;
      }

      dispatch(setDadosEmpregado(empregado));
    }
  } catch (error) {
    dispatch(
      Functions.tratamentoErroRequest(
        error,
        'Dados do Usuário',
        `Opps... Erro ao buscar dados do empregado \n Erro: ${error.response}`,
      ),
    );
  }
};

export const getPessoaLogada = () => async (dispatch) => {
  await connect
    .get('/pessoas-usuarios/pessoa-logada')
    .then((responsePessoa) => {
      if (responsePessoa.status === 200) {
        dispatch(setPessoaLogada(responsePessoa));

        if (responsePessoa.data.id && responsePessoa.data.status) {
          if (responsePessoa.data.nome !== 'Super Usuário')
            dispatch(getEmpregadoPessoa(responsePessoa.data.inscricao));
        } else {
          hashHistory.push('/acesso-nao-autorizado');
        }
      }
    })
    .catch((error) => {
      if (error.response.data) {
        toastr.warning('Dados do Usuário', `${error.response.data.erro}`, { timeOut: 8000 });
      } else {
        toastr.error('Dados do Usuário', `${error.message}`, { timeOut: 8000 });
      }
    });
};
