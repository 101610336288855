import AcessoApp from 'ajuda-inicializacao';

import {
  ROT_GET_ROTAS_PERMITIDAS,
  ROT_SET_MENUS,
  ROT_SET_ROTAS_PERMITIDAS,
} from '../actionTypes/actionTypes';
import ColetaColetiva from '../pages/coletaColetiva/';

const INITIAL_STATE = {
  rotas: [
    {
      path: '/acesso-app',
      exact: true,
      component: AcessoApp,
      permitido: true,
    },
    {
      path: '/coleta-coletiva',
      exact: true,
      component: ColetaColetiva,
      permitido: true,
    },
  ],
  menus: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ROT_GET_ROTAS_PERMITIDAS:
      return { ...state, rotas: payload.data.rotasPermitidas };
    case ROT_SET_ROTAS_PERMITIDAS:
      return { ...state, rotas: payload };
    case ROT_SET_MENUS:
      return { ...state, menus: payload };
    default:
      return state;
  }
};
