import {
  ENM_GET_BANCOS,
  ENM_GET_CATEGORIAS,
  ENM_GET_CATEGORIAS_CONTRIBUINTE,
  ENM_GET_CIDADES,
  ENM_GET_PAISES,
  ENM_GET_CLASSIFICACOES_ESTRANGEIRO,
  ENM_GET_CONTRATO_PRAZO,
  ENM_GET_ESTADOS_CIVIS,
  ENM_GET_FORMAS_HORARIO_TRABALHO,
  ENM_GET_FORMAS_PAGAMENTO,
  ENM_GET_FORMAS_TRABALHO,
  ENM_GET_GENEROS,
  ENM_GET_GRAUS_INSTRUCAO,
  ENM_GET_INDICATIVOS_ADMISSAO,
  ENM_GET_JORNADAS_TRABALHO,
  ENM_GET_LOCAIS_SERVICO,
  ENM_GET_MOTIVOS_CONTRACAO,
  ENM_GET_NATUREZA_ESTAGIO,
  ENM_GET_NIVEL_ESTAGIO,
  ENM_GET_PARAMETROS_SALARIAL,
  ENM_GET_PARENTESCOS,
  ENM_GET_PERIODOS,
  ENM_GET_RACAS,
  ENM_GET_RELACOES_DEPENDENCIA,
  ENM_GET_SITUACOES,
  ENM_GET_TIMEZONE,
  ENM_GET_TIPOS_ADMISSAO,
  ENM_GET_TIPOS_AFASTAMENTO,
  ENM_GET_TIPOS_AVISO,
  ENM_GET_TIPOS_CERTIDAO,
  ENM_GET_TIPOS_CONTA,
  ENM_GET_TIPOS_CONTRIBUINTE,
  ENM_GET_TIPOS_DIA,
  ENM_GET_TIPOS_FICHA_FINANCEIRA,
  ENM_GET_TIPOS_FOLHA,
  ENM_GET_TIPOS_FOLHA_RESUMO,
  ENM_GET_TIPOS_INCLUSAO_CONTRATO,
  ENM_GET_TIPOS_LOGRADOURO,
  ENM_GET_TIPOS_MOTIVO,
  ENM_GET_TIPOS_PAGAMENTO,
  ENM_GET_TIPOS_REDUCAO,
  ENM_GET_TIPOS_SALARIO,
  ENM_GET_UNIDADES,
  ENM_GET_UNIDADES_FEDERATIVAS,
  ENM_GET_VINCULOS,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  tiposEmpregado: [],
  generos: [],
  estadosCivis: [],
  racas: [],
  grausInstrucao: [],
  tiposLogradouro: [],
  formasPagamento: [],
  bancos: [],
  tiposConta: [],
  unidadesFederativas: [],
  tiposCertidao: [],
  tiposAdmissao: [],
  vinculos: [],
  categorias: [],
  parametrosSalarial: [],
  tiposPagamento: [],
  tiposSalario: [],
  jornadasTrabalho: [],
  formasHorarioTrabalho: [],
  formasTrabalho: [],
  indicativosAdmissao: [],
  tiposInclusaoContrato: [],
  motivosContratacao: [],
  classificacoesEstrangeiro: [],
  cidades: [],
  paises: [],
  tiposAfastamento: [],
  tiposAviso: [],
  tiposReducao: [],
  relacoesDependencia: [],
  parentescos: [],
  locaisServico: [],
  tiposFolha: [],
  tiposFichaFinanceira: [],
  periodos: [],
  tiposFolhaResumo: [],
  tiposDia: [],
  unidades: [],
  tiposContribuinte: [],
  categoriasContribuinte: [],
  timezones: [],
  situacoes: [],
  tiposMotivo: [],
  contratosPrazo: [],
  naturezaEstagio: [],
  nivelEstagio: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case ENM_GET_GENEROS:
      return { ...state, generos: payload };
    case ENM_GET_ESTADOS_CIVIS:
      return { ...state, estadosCivis: payload };
    case ENM_GET_CIDADES:
      return { ...state, cidades: payload };
    case ENM_GET_PAISES:
      return { ...state, paises: payload };
    case ENM_GET_RACAS:
      return { ...state, racas: payload };
    case ENM_GET_GRAUS_INSTRUCAO:
      return { ...state, grausInstrucao: payload };
    case ENM_GET_TIPOS_LOGRADOURO:
      return { ...state, tiposLogradouro: payload };
    case ENM_GET_FORMAS_PAGAMENTO:
      return { ...state, formasPagamento: payload };
    case ENM_GET_BANCOS:
      return { ...state, bancos: payload };
    case ENM_GET_TIPOS_CONTA:
      return { ...state, tiposConta: payload };
    case ENM_GET_UNIDADES_FEDERATIVAS:
      return { ...state, unidadesFederativas: payload };
    case ENM_GET_TIPOS_CERTIDAO:
      return { ...state, tiposCertidao: payload };
    case ENM_GET_TIPOS_ADMISSAO:
      return { ...state, tiposAdmissao: payload };
    case ENM_GET_VINCULOS:
      return { ...state, vinculos: payload };
    case ENM_GET_CATEGORIAS:
      return { ...state, categorias: payload };
    case ENM_GET_PARAMETROS_SALARIAL:
      return { ...state, parametrosSalarial: payload };
    case ENM_GET_TIPOS_PAGAMENTO:
      return { ...state, tiposPagamento: payload };
    case ENM_GET_TIPOS_SALARIO:
      return { ...state, tiposSalario: payload };
    case ENM_GET_JORNADAS_TRABALHO:
      return { ...state, jornadasTrabalho: payload };
    case ENM_GET_FORMAS_HORARIO_TRABALHO:
      return { ...state, formasHorarioTrabalho: payload };
    case ENM_GET_FORMAS_TRABALHO:
      return { ...state, formasTrabalho: payload };
    case ENM_GET_INDICATIVOS_ADMISSAO:
      return { ...state, indicativosAdmissao: payload };
    case ENM_GET_TIPOS_INCLUSAO_CONTRATO:
      return { ...state, tiposInclusaoContrato: payload };
    case ENM_GET_MOTIVOS_CONTRACAO:
      return { ...state, motivosContratacao: payload };
    case ENM_GET_CLASSIFICACOES_ESTRANGEIRO:
      return { ...state, classificacoesEstrangeiro: payload };
    case ENM_GET_TIPOS_AFASTAMENTO:
      return { ...state, tiposAfastamento: payload };
    case ENM_GET_TIPOS_AVISO:
      return { ...state, tiposAviso: payload };
    case ENM_GET_TIPOS_REDUCAO:
      return { ...state, tiposReducao: payload };
    case ENM_GET_RELACOES_DEPENDENCIA:
      return { ...state, relacoesDependencia: payload };
    case ENM_GET_PARENTESCOS:
      return { ...state, parentescos: payload };
    case ENM_GET_LOCAIS_SERVICO:
      return { ...state, locaisServico: payload };
    case ENM_GET_TIPOS_FOLHA:
      return { ...state, tiposFolha: payload };
    case ENM_GET_TIPOS_FICHA_FINANCEIRA:
      return { ...state, tiposFichaFinanceira: payload };
    case ENM_GET_PERIODOS:
      return { ...state, periodos: payload };
    case ENM_GET_TIPOS_FOLHA_RESUMO:
      return { ...state, tiposFolhaResumo: payload };
    case ENM_GET_TIPOS_DIA:
      return { ...state, tiposDia: payload };
    case ENM_GET_UNIDADES:
      return { ...state, unidades: payload };
    case ENM_GET_TIPOS_CONTRIBUINTE:
      return { ...state, tiposContribuinte: payload };
    case ENM_GET_CATEGORIAS_CONTRIBUINTE:
      return { ...state, categoriasContribuinte: payload };
    case ENM_GET_TIMEZONE:
      return { ...state, timezones: payload };
    case ENM_GET_SITUACOES:
      return { ...state, situacoes: payload };
    case ENM_GET_TIPOS_MOTIVO:
      return { ...state, tiposMotivo: payload };
    case ENM_GET_CONTRATO_PRAZO:
      return { ...state, contratosPrazo: payload };
    case ENM_GET_NATUREZA_ESTAGIO:
      return { ...state, naturezaEstagio: payload };
    case ENM_GET_NIVEL_ESTAGIO:
      return { ...state, nivelEstagio: payload };
    default:
      return { ...state };
  }
};
