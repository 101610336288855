import * as a from '../actionTypes/actionTypes'

const INITIAL_STATE = {
    processandoResumoFolha: false,
    resumosFolha: []
}

export default (state = INITIAL_STATE, action) => {

    const { type, payload } = action

    switch (type) {
        case a.RSF_SET_ESTADO_INICIAL:
            return { ...state, processandoResumoFolha: false, resumosFolha: [] }
        case a.RSF_GET_RESUMOS_FOLHA:
            return { ...state, resumosFolha: payload }
        case a.RSF_PROCESSANDO_RESUMOS_FOLHA:
            return { ...state, processandoResumoFolha: payload }
        default:
            return { ...state };
    }
}
