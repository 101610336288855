import {
  PRE_GET_EVENTOS,
  PRE_GET_PROGRAMACOES_EVENTOS,
  PRE_PROCESSANDO_EVENTOS,
  PRE_PROCESSANDO_PROGRAMACAO_EVENTOS,
  PRE_SET_ESTADO_INICIAL,
  PRE_SET_ARQUIVOS_IMPORTACAO,
  PRE_SET_DATA_SOURCE,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoProgramacao: false,
  processandoEventos: false,
  programacoes: [],
  eventos: [],
  arquivosImportacao: [],
  dataSource: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRE_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoProgramacao: false,
        processandoEventos: false,
        programacoes: [],
        eventos: [],
        arquivosImportacao: [],
        dataSource: [],
      };
    case PRE_GET_PROGRAMACOES_EVENTOS:
      return { ...state, programacoes: payload };
    case PRE_PROCESSANDO_PROGRAMACAO_EVENTOS:
      return { ...state, processandoProgramacao: payload };
    case PRE_PROCESSANDO_EVENTOS:
      return { ...state, processandoEventos: payload };
    case PRE_GET_EVENTOS:
      return { ...state, eventos: payload };
    case PRE_SET_ARQUIVOS_IMPORTACAO:
      return { ...state, arquivosImportacao: payload };
    case PRE_SET_DATA_SOURCE:
      return { ...state, dataSource: payload };
    default:
      return { ...state };
  }
};
