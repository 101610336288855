import React from 'react';

import { Button, Icon } from 'antd';


function TecladoNumerico({ pressionaTecla }) {
  const buttonRender = (keycode, numeroButton, margin) => (
    <Button
      onClick={() => pressionaTecla(keycode)}
      style={{
        width: keycode === 48 ? '235px' : '116px',
        height: '96px',
        fontSize: '32px',
        fontWeight: 'bold',
        marginLeft: margin ? '2px' : '0px',
        marginRight: margin ? '2px' : '0px',
        marginTop: keycode === 48 ? '-9px' : '0px',
      }}
      className="btn btn-sm btn-primary text-center">
      {numeroButton}
    </Button>
  );

  return (
    <div className="flex-coletor-column flex-align-center" style={{ marginBottom: 10 }}>
      <div className="flex-row-123">
        <div style={{ flex: 1 }}>
          {buttonRender(49, 1)}
          {buttonRender(50, 2, true)}
          {buttonRender(51, 3)}
        </div>
      </div>
      <div className="flex-row-num-456">
        {buttonRender(52, 4)}
        {buttonRender(53, 5, true)}
        {buttonRender(54, 6)}
      </div>
      <div className="row">
        <div style={{ flex: 1 }}>
          {buttonRender(55, 7)}
          {buttonRender(56, 8, true)}
          {buttonRender(57, 9)}
        </div>
      </div>
      <div className="row" style={{ marginTop: 2 }}>
        <div style={{ flex: 1 }}>
          <Button
            onClick={() => pressionaTecla(192)}
            style={{
              width: '116px',
              marginLeft: '0px',
              marginRight: '0px',
              height: '96px',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              backgroundColor: '#7F222D',
            }}>
            <Icon style={{ fontSize: '25px', color: '#fff' }} type="left-circle" />
          </Button>
          {buttonRender(48, 0, true)}
        </div>
      </div>
    </div>
  );
}
export default TecladoNumerico;
