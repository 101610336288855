import moment from 'moment';

import {
  GFR_PROCESSANDO_FREQUENCIA,
  GFR_PROCESSANDO_LOCALIZACAO,
  GFR_SET_AUSENTE,
  GFR_SET_DATA,
  GFR_SET_ESTADO_INICIAL,
  GFR_SET_FREQUENCIAS,
  GFR_SET_IRREGULARES,
  GFR_SET_LOCALIZACAO_FICTICIA,
  GFR_SET_MODAL_VISIBLE,
  GFR_SET_DISPOSITIVOS_NAO_RECONHECIDOS,
  GFR_PROCESSANDO_DISPOSITIVOS,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoFrequencia: false,
  modalVisible: false,
  data: moment().format('YYYYMMDD'),
  ausentes: [],
  irregulares: [],
  processandoLocalizacao: false,
  localizacoesFicticias: [],
  frequencias: [],
  dispositivosNaoReconhecidos: [],
  processandoDispositivos: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GFR_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoFrequencia: false,
        modalVisible: false,
        data: moment().format('YYYYMMDD'),
        ausentes: [],
        irregulares: [],
        processandoLocalizacao: false,
        localizacoesFicticias: [],
        frequencias: [],
        dispositivosNaoReconhecidos: [],
        processandoDispositivos: false,
      };
    case GFR_SET_AUSENTE:
      return { ...state, ausentes: payload };
    case GFR_SET_IRREGULARES:
      return { ...state, irregulares: payload };
    case GFR_PROCESSANDO_FREQUENCIA:
      return { ...state, processandoFrequencia: payload };
    case GFR_SET_MODAL_VISIBLE:
      return { ...state, modalVisible: payload };
    case GFR_SET_DATA:
      return { ...state, data: payload };
    case GFR_PROCESSANDO_LOCALIZACAO:
      return { ...state, processandoLocalizacao: payload };
    case GFR_SET_LOCALIZACAO_FICTICIA:
      return { ...state, localizacoesFicticias: payload };
    case GFR_SET_FREQUENCIAS:
      return { ...state, frequencias: payload };
    case GFR_SET_DISPOSITIVOS_NAO_RECONHECIDOS:
      return { ...state, dispositivosNaoReconhecidos: payload };
    case GFR_PROCESSANDO_DISPOSITIVOS:
      return { ...state, processandoDispositivos: payload };
    default:
      return { ...state };
  }
};
