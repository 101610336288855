import {
  JUS_GET_FREQUENCIA,
  JUS_GET_JUSTIFICATIVAS_FALTA,
  JUS_PROCESSANDO_JUSTIFICATIVA_FALTA,
  JUS_SET_ESTADO_INICIAL,
  JUS_SET_MODAL_VISIBLE_RECUSAR,
  JUS_SET_QUANTIDADE_PENDENTES,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  quantidadeJustificativasPendentes: 0,
  processandoJustificativaFalta: false,
  justificativasFalta: [],
  frequencia: null,
  visibleRecusar: false,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case JUS_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoJustificativaFalta: false,
        justificativasFalta: [],
        frequencia: null,
        quantidadeJustificativasPendentes: 0,
        visibleRecusar: false,
      };
    case JUS_GET_JUSTIFICATIVAS_FALTA:
      return { ...state, justificativasFalta: payload };
    case JUS_PROCESSANDO_JUSTIFICATIVA_FALTA:
      return { ...state, processandoJustificativaFalta: payload };
    case JUS_GET_FREQUENCIA:
      return { ...state, frequencia: payload };
    case JUS_SET_QUANTIDADE_PENDENTES:
      return { ...state, quantidadeJustificativasPendentes: payload };
    case JUS_SET_MODAL_VISIBLE_RECUSAR:
      return { ...state, visibleRecusar: payload };
    default:
      return { ...state };
  }
};
