import { PDF_SET_ESTADO_INICIAL, PDF_SET_PDF_URL } from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  pdfUrl: undefined,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PDF_SET_ESTADO_INICIAL:
      return { ...state, pdfUrl: undefined };
    case PDF_SET_PDF_URL:
      return { ...state, pdfUrl: payload };
    default:
      return { ...state };
  }
};
