import {
  FRE_GET_FREQUENCIA_DIA,
  FRE_GET_FREQUENCIAS,
  FRE_PROCESSANDO_FREQUENCIAS,
  FRE_SET_ESTADO_INICIAL,
  FRE_SET_HORAS_TRABALHADAS,
  FRE_SET_HORAS_UTEIS,
  FRE_SET_SALDO_HORAS,
  FRE_SET_TOTALIZADORES,
  FRE_SET_VISIBILIDADE_MODAL_LOCALIZACAO,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoFrequencia: false,
  frequencias: [],
  totalCredito: '00:00',
  totalDebito: '00:00',
  balancoTotal: '00:00',
  horasUteis: '',
  horasTrabalhadas: '',
  saldohoras: '00:00',
  modalVisivel: false,
  localizacaoPonto: null,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FRE_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoFrequencia: false,
        frequencias: [],
        totalCredito: '00:00',
        totalDebito: '00:00',
        balancoTotal: '00:00',
        modalVisivel: false,
        localizacaoPonto: null,
      };
    case FRE_GET_FREQUENCIAS:
      return { ...state, frequencias: payload };
    case FRE_PROCESSANDO_FREQUENCIAS:
      return { ...state, processandoFrequencia: payload };
    case FRE_SET_TOTALIZADORES:
      return {
        ...state,
        totalCredito: payload.totalCredito,
        totalDebito: payload.totalDebito,
        balancoTotal: payload.balancoTotal,
      };
    case FRE_GET_FREQUENCIA_DIA:
      return { ...state, frequencias: payload };
    case FRE_SET_HORAS_UTEIS:
      return { ...state, horasUteis: payload };
    case FRE_SET_HORAS_TRABALHADAS:
      return { ...state, horasTrabalhadas: payload };
    case FRE_SET_SALDO_HORAS:
      return { ...state, saldohoras: payload };
    case FRE_SET_VISIBILIDADE_MODAL_LOCALIZACAO:
      return {
        ...state,
        modalVisivel: payload.modalVisivel,
        localizacaoPonto: payload.localizacaoPonto,
      };
    default:
      return { ...state };
  }
};
