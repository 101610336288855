import {
  PRG_GET_PROGRAMACOES_FERIAS,
  PRG_PROCESSANDO_PROGRAMACAO_FERIAS,
  PRG_SET_ARQUIVOS_IMPORTACAO,
  PRG_SET_ESTADO_INICIAL,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoProgramacao: false,
  programacoes: [],
  arquivosImportacao: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PRG_SET_ESTADO_INICIAL:
      return {
        ...state, processandoProgramacao: false, programacoes: [], arquivosImportacao: [],
      };
    case PRG_GET_PROGRAMACOES_FERIAS:
      return { ...state, programacoes: payload };
    case PRG_PROCESSANDO_PROGRAMACAO_FERIAS:
      return { ...state, processandoProgramacao: payload };
    case PRG_SET_ARQUIVOS_IMPORTACAO:
      return { ...state, arquivosImportacao: payload };
    default:
      return { ...state };
  }
};
