/* eslint-disable arrow-parens */
import React, { useState } from 'react';

import { connect } from 'react-redux';

import './assets/css/popup.css';
import crosscircle from './assets/images/icons/cross-circle.svg';
import novidade from './assets/images/navigation/novidade.png';
import saibamais from './assets/images/navigation/saibamais.png';

function Popup({ rota, dispatch }) {
  const [isOpen, setIsOpen] = useState(true);

  const closePopup = () => {
    setIsOpen(false);
  };

  const isRotaArray = Array.isArray(rota.menus);

  // Check if there is an object in the 'rota' array that meets the specified criteria
  const isPontoPermitido =
    isRotaArray && rota.menus.some((item) => item.funcaoDescricao === 'Ponto' && item.permitido);

  return (
    <>
      {isOpen && (
        <div className="row" style={{ backgroundColor: 'darkgreen' }}>
          <div className="texts">
            <div className="col-md-2" />
            <div className="col-md-12">
              <div className="row" style={{ backgroundColor: 'darkgreen', margin: '13px' }}>
                <div className="col-md-2" />
                <div className="col-md-1">
                  <a
                    target="_blank"
                    href="https://bit.ly/ReconhecimentoFacialColetivo"
                    rel="noreferrer">
                    <img alt="popup" src={novidade} />
                  </a>
                </div>
                <div className="col-md-6">
                  {isPontoPermitido ? (
                    <>
                      Novo recurso disponível, habilite o registro de ponto por reconhecimento
                      facial coletivo.
                    </>
                  ) : (
                    <>
                       Evolua o registro de ponto da sua empresa com o reconhecimento facial
                      coletivo.
                    </>
                  )}
                </div>
                <div className="col-md-1">
                  <a
                    target="_blank"
                    href="https://bit.ly/ReconhecimentoFacialColetivo"
                    rel="noreferrer">
                    <img alt="popup" src={saibamais} style={{ width: '105px' }} />
                  </a>
                </div>

                <div className="col-md-2">
                  <div className="pull-right">
                    <span className="" onClick={closePopup}>
                      <img alt="popup" src={crosscircle} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  rota: state.rota,
});

export default connect(mapStateToProps)(Popup);
