import {
  EVP_SET_ESTADO_INICIAL,
  EVP_GET_EVENTOS_PONTO,
  EVP_PROCESSANDO_EVENTOS_PONTO,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoEventoPonto: false,
  eventosPonto: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case EVP_SET_ESTADO_INICIAL:
      return { ...state, processandoEventoPonto: false, eventosPonto: [] };
    case EVP_GET_EVENTOS_PONTO:
      return { ...state, eventosPonto: payload };
    case EVP_PROCESSANDO_EVENTOS_PONTO:
      return { ...state, processandoEventoPonto: payload };
    default:
      return { ...state };
  }
};
