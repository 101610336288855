import {
  FPT_SET_ESTADO_INICIAL,
  FPT_GET_FOLHAS_PONTO,
  FPT_PROCESSANDO_FOLHAS_PONTO,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoFolhaPonto: false,
  folhasPonto: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FPT_SET_ESTADO_INICIAL:
      return { ...state, processandoFolhaPonto: false, folhasPonto: [] };
    case FPT_GET_FOLHAS_PONTO:
      return { ...state, folhasPonto: payload };
    case FPT_PROCESSANDO_FOLHAS_PONTO:
      return { ...state, processandoFolhaPonto: payload };
    default:
      return { ...state };
  }
};
