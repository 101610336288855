import swal from 'sweetalert';

import {
  COC_REGISTRO_COLETIVO_FALHA,
  COC_REGISTRO_COLETIVO_SUCESSO
} from '../actionTypes/actionTypes';
// eslint-disable-next-line import/no-cycle
import connect from '../api/connect';


export const setRegistroColetivoSucesso = payload => ({
  type: COC_REGISTRO_COLETIVO_SUCESSO,
  payload,
});

export const setRegistroColetivoFalha = () => ({
  type: COC_REGISTRO_COLETIVO_FALHA,
});

export const postColetaColetivaPonto = (dados, cb) => async (dispatch) => {
  cb(true);

  try {
    const { data } = await connect.post('frequencias/registro-coletivo', dados);

    dispatch(setRegistroColetivoSucesso(data));

    cb(false);

    swal({
      title: 'Registro Efetuado!',
      text: data.nomeEmpresa,
      icon: 'success',
      timer: 5000,
      timerProgressBar: true,
      content: {
        element: 'p',
        attributes: {
          textContent: `\n\n${data.nomeEmpregado} seu comprovante está disponível no aplicativo ou através do portal Tron Connect.`,
        },
      },
    });
  } catch ({ response }) {
    dispatch(setRegistroColetivoFalha());

    if (response) {
      const {
        data: { erro },
      } = response;

      cb(false);

      const [erroMessage] = erro;

      swal({
        title: 'Não foi possível registrar seu ponto!',
        text: erroMessage.mensagem,
        icon: 'error',
        timer: 10000,
        timerProgressBar: true,
      });
    }
  }
};
