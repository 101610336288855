import React, { Component } from 'react';

import { Button, Result, Spin, Typography } from 'antd';
import $ from 'jquery';
import { connect } from 'react-redux';

import Consts from '../helpers/consts/pageConsts';
import ColetaColetiva from '../pages/coletaColetiva';
import sentinelaFront from '../util/sentinelaFront';
import pace from './assets/js/pace/pace';
import Loading from './components/loading';
import Footer from './footer';
import { correctHeight, detectBody } from './helpers/helpers';
import Messages from './messages';
import Navigation from './navigation';
import Popup from './popup';
import TopHeader from './topHeader';

const { Paragraph, Text } = Typography;

class Main extends Component {
  componentDidMount() {
    $(window).bind('load resize', () => {
      correctHeight();
      detectBody();
    });

    $('.metismenu a').click(() => {
      setTimeout(() => {
        correctHeight();
      }, 300);
    });
    pace.start();
  }

  renderMain() {
    return (
      <Spin spinning={this.props.default.carregandoAplicacao} size="large">
        <Popup />
        <div id="wrapper">
          <Navigation />
          <div id="page-wrapper" className="gray-bg">
            <TopHeader />
            {this.props.children}
            <Footer />
            <Messages />
          </div>
        </div>
      </Spin>
    );
  }

  render() {
    if (this.props.default.carregandoAplicacao)
      return (
        <>
          <Loading title="Estamos preparando tudo para você! Aguarde..." type="bars" />
          {this.props.children}
        </>
      );

    if (this.props.usuario.pessoaLogada.grupoUsuarioId !== Consts.ID_GRUPO_USUARIO_SUPER_USUARIO) {
      if (
        !window.location.hash.includes('coleta-coletiva') &&
        (!this.props.usuario.pessoaLogada.pessoaUsuarioJaExiste ||
          !this.props.usuario.pessoaLogada.status)
      ) {
        return (
          <div id="bg-login">
            <div className="container-error">
              <Result
                status="error"
                title="Acesso Negado"
                subTitle={Consts.MENSAGEM_CADASTRO_INCOMPLETO}
                extra={[
                  <Button type="primary" href={sentinelaFront[process.env.NODE_ENV]}>
                    Voltar
                  </Button>,
                ]}>
                <div className="desc">
                  <Paragraph>
                    <Text
                      strong
                      style={{
                        fontSize: 16,
                      }}>
                      O acesso foi negado por um dos motivos abaixo:
                    </Text>
                  </Paragraph>
                  <Paragraph>
                    <i className="fa fa-times text-danger" /> O mesmo e-mail está cadastrado para
                    duas pessoas.
                  </Paragraph>
                  <Paragraph>
                    <i className="fa fa-times text-danger" /> Seu cadastro de usuário na empresa
                    está incompleto.
                  </Paragraph>
                  <Paragraph>
                    <i className="fa fa-times text-danger" /> Seu acesso foi desativado pelo usuário
                    administrador da empresa.
                  </Paragraph>
                </div>
              </Result>
            </div>
          </div>
        );
      }
      if (window.location.hash.includes('coleta-coletiva')) {
        return <ColetaColetiva />;
      }
      return this.renderMain();
    }
    if (window.location.hash.includes('coleta-coletiva')) {
      return <ColetaColetiva />;
    }
    return this.renderMain();
  }
}

const mapStateToProps = (state) => ({ default: state.default, usuario: state.usuario });

export default connect(mapStateToProps, null, null, { forwardRef: true })(Main);
