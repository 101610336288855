import { Menu, Tooltip } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';

import { logOutUser, showModal } from '../actions/defaultActions';
import sentinelaFront from '../util/sentinelaFront';
import { smoothlyMenu } from './helpers/helpers';

const itemMenuStyle = {
  backgroundColor: 'transparent',
  boxShadow: 'none',
  borderBottom: 'none',
};

class TopHeader extends Component {
  toggleNavigation(e) {
    e.preventDefault();
    $('body').toggleClass('mini-navbar');
    smoothlyMenu();
  }

  render() {
    const {
      usuario: { pessoaLogada },
    } = this.props;

    return (
      <div className="row border-bottom">
        <nav className="navbar navbar-static-top" role="navigation" style={{ marginBottom: 0 }}>
          <div className="navbar-header">
            <a
              className="minimalize-styl-2 btn btn-primary"
              onClick={e => this.toggleNavigation(e)}
              href="#">
              <i className="fa fa-bars" />
            </a>
          </div>
          <ul className="nav navbar-top-links navbar-right">
            <li>
              <span className="m-r-sm text-muted welcome-message">{pessoaLogada.empresa}</span>
            </li>
            {this.props.default.empresas.length > 1 && (
              <li>
                <Tooltip title="Trocar Empresa">
                  <a onClick={() => this.props.showModal()}>
                    <i className="fa fa-retweet" />
                  </a>
                </Tooltip>
              </li>
            )}

            <li>
              <Menu mode="horizontal" style={itemMenuStyle}>
                <Menu.SubMenu title={<i className="fa fa-caret-down fa-lg" />}>
                  <Menu.Item key="1">
                    <a
                      href={`${sentinelaFront[process.env.NODE_ENV]}/#/`}
                      onClick={this.props.logOutUser}>
                      <i className="fa fa-sign-in" /> Acessar meus produtos
                    </a>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <a href="#/configuracoes">
                      <i className="fa fa-cog" /> Configurações
                    </a>
                  </Menu.Item>
                  <Menu.Divider></Menu.Divider>
                  <Menu.Item key="3">
                    <a
                      href={`${sentinelaFront[process.env.NODE_ENV]}/#/logout`}
                      onClick={this.props.logOutUser}>
                      <i className="fa fa-sign-out" /> Sair
                    </a>
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            </li>

            <li></li>
          </ul>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = state => ({ default: state.default, usuario: state.usuario });

const mapDispatchToProps = { logOutUser, showModal };

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TopHeader);
