import * as a from './../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoAfastamento: false,
  afastamentos: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case a.AFA_SET_ESTADO_INICIAL:
      return { ...state, processandoAfastamento: false, afastamentos: [] };
    case a.AFA_GET_AFASTAMENTOS:
      return { ...state, afastamentos: payload };
    case a.AFA_PROCESSANDO_AFASTAMENTO:
      return { ...state, processandoAfastamento: payload };
    default:
      return { ...state };
  }
};
