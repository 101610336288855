import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { getRotasPermitidas } from '../actions/rotaActions';
import { getPessoaLogada, verificaPessoaLogada } from '../actions/usuarioActions';
import Routers from '../router';
import { TodoFixLaterType } from '../types/generic';
import Main from './main';

interface StateProps {
  pessoaJaLogada: TodoFixLaterType;
}

interface DispatchProps {
  getPessoaAction: () => void;
  checkPessoaAction: () => void;
  getRotaAction: () => void;
}

type HomeProps = StateProps & DispatchProps;

function Home({ pessoaJaLogada, getPessoaAction, checkPessoaAction, getRotaAction }: HomeProps) {
  async function setup() {
    await checkPessoaAction();

    if (!pessoaJaLogada) await getPessoaAction();

    await getRotaAction();
  }

  useEffect(() => {
    if (!window.location.hash.includes('coleta-coletiva')) setup();
  }, []);

  return (
    <Main>
      <Routers />
    </Main>
  );
}

const mapStateToProps = (state: TodoFixLaterType) => ({
  pessoaJaLogada: state.usuario.pessoaJaLogada,
  rota: state.rota,
});

const mapDispatchToProps = {
  getPessoaAction: getPessoaLogada,
  checkPessoaAction: verificaPessoaLogada,
  getRotaAction: getRotasPermitidas,
};

export default connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Home);
