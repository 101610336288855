import * as a from '../actionTypes/actionTypes'

const INITIAL_STATE = {
    processandoFichaFinanceira: false,
    fichasFinanceira: []
}

export default ( state = INITIAL_STATE, action ) =>{

    const { type, payload } = action

    switch (type) {
        case a.AVF_SET_ESTADO_INICIAL:
            return { ...state, processandoFichaFinanceira: false, fichasFinanceira: [] }
        case a.FIF_GET_FICHAS_FINANCEIRA:
            return { ...state, fichasFinanceira: payload }
        case a.AVF_PROCESSANDO_AVISOS_FERIAS:
            return { ...state, processandoFichaFinanceira: payload }
        default:
            return { ...state };
    }
}
