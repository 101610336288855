/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-regex-literals */
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

const errorRequestMessageProperties = ['erro', 'erros', 'mensagem', 'message'];

const getErrorRequestMessage = (error) => {
  const data = error?.response?.data;
  if (!data) return null;

  const propertyWithMessage = errorRequestMessageProperties.find(property => data[property]);
  if (!propertyWithMessage) return null;

  return data[propertyWithMessage];
};

class Functions {
  static tratamentoErroRequest(err, chaveDefault, mensagemDefault) {
    const displayDefaultMessage = () => toastr.error(chaveDefault, mensagemDefault);

    const message = getErrorRequestMessage(err);
    if (!message) {
      displayDefaultMessage();
      return;
    }

    if (_.isArray(message)) {
      const validErrorMessages = message.filter(error => error.chave && error.mensagem);

      if (validErrorMessages.length === 0) {
        displayDefaultMessage();
        return;
      }

      _.forEach(validErrorMessages, error => toastr.error(error.chave, error.mensagem));
    } else {
      toastr.error(chaveDefault, message);
    }
  }

  static putRegistryMask(registry) {
    if (registry) {
      if (registry.length === 14) {
        return registry.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/, '$1.$2.$3/$4-$5');
      }
      if (registry.length === 12) {
        return registry.replace(/^(\d{11})(\d{1}).*/, '$1-$2');
      }
      return registry.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
    }
  }

  static findObjectByKey(array, key, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][key] === value) {
        return array[i];
      }
    }
    return null;
  }

  static removeAcento(text) {
    text = text.toLowerCase();
    text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
    return text;
  }

  static validaCnpj(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj === '') return false;

    if (cnpj.length !== 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    ) {
      return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== digitos.charAt(0)) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== digitos.charAt(1)) return false;

    return true;
  }

  static guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  static isGuid(value) {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const match = regex.exec(value);
    return match != null;
  }

  static isEmptyGuid(value) {
    return value === '00000000-0000-0000-0000-000000000000';
  }

  static isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  static dataBase64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}

export default Functions;
