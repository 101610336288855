import * as a from '../actionTypes/actionTypes'

const INITIAL_STATE = {
    processandoMotivo: false,
    motivos: []
}

export default (state = INITIAL_STATE, action) => {

    const { type, payload } = action

    switch (type) {
        case a.MOT_SET_ESTADO_INICIAL:
            return { ...state, processandoMotivo: false, motivos: [] }
        case a.MOT_SET_MOTIVOS:
            return { ...state, motivos: payload }
        case a.MOT_PROCESSANDO_MOTIVOS:
            return { ...state, processandoMotivo: payload }
        default:
            return { ...state };
    }
}
