import './components/styles/coletorColetivo.css';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { postColetaColetivaPonto } from '../../actions/coletaColetivaAction';
import { setCarregandoAplicacao } from '../../actions/defaultActions';
import logoPontoRemoto from '../../theme/assets/images/logo/logoPontoRemoto.png';
import Footer from '../../theme/footer';
import InputsDeRegistro from './components/inputsDeRegistro';
import TecladoNumerico from './components/tecladoNumerico';
import RelogioDigital from './components/relogioDigital';

function ColetaColetiva({ postAction, setCarregandoAplicacaoAction }) {
  useEffect(() => {
    sessionStorage.clear();
    setCarregandoAplicacaoAction(false);
  }, []);

  function pressionaTecla(keyCode) {
    document.dispatchEvent(new KeyboardEvent('keydown', { keyCode }));
  }

  return (
    <div style={{ backgroundColor: '#e8e8e8', height: window.innerHeight }}>
      <div style={{ height: '850px' }} className="wrapper wrapper-content animated fadeIn">
        <div className="container-coletor">
          <div className="ibox-content ibox-coletor">
            <div className="flex-coletor-column">
              <div className="flex-logo">
                <img alt="logo" className="mr-1 logo-coletor" src={logoPontoRemoto} />
              </div>
              <div className="flex-coletor-row">
                <TecladoNumerico pressionaTecla={pressionaTecla} />
                <div className="flex-coletor-column">
                  <RelogioDigital />
                  <InputsDeRegistro postAction={postAction} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer fullPage />
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  postAction: postColetaColetivaPonto,
  setCarregandoAplicacaoAction: setCarregandoAplicacao,
};
export default connect(null, mapDispatchToProps, null, { forwardRef: true })(ColetaColetiva);
