import {
  EMP_CREATE_SET_INITIAL_STATE,
  EMP_PROCESSANDO_PESSOAS,
  EMP_SET_CURRENT_STEP,
  EMP_SET_ESTADO_INICIAL,
  EMP_SET_PESSOAS,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoPessoas: false,
  pessoas: [],
  currentStep: 0,
  statusStep: 'process',
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case EMP_SET_ESTADO_INICIAL:
      return { ...state, processandoPessoas: false, pessoas: [] };
    case EMP_SET_PESSOAS:
      return { ...state, pessoas: payload };
    case EMP_PROCESSANDO_PESSOAS:
      return { ...state, processandoPessoas: payload };
    case EMP_SET_CURRENT_STEP:
      return { ...state, currentStep: payload.current, statusStep: payload.status };
    case EMP_CREATE_SET_INITIAL_STATE:
      return { ...state, currentStep: 0, statusStep: 'process' };
    default:
      return { ...state };
  }
};
