/**
 * Constants routes.
 */
const PagesConstants = {
  MENSAGEM_RETORNO:
    'Demonstra a descrição de retorno quando o cadastro estiver com a situação de recusado.',
  ID_GRUPO_USUARIO_SUPER_USUARIO: '315ff38e-d541-4835-9193-0ccd8f2c4619',
  MENSAGEM_CADASTRO_INCOMPLETO:
    'É necessário entrar em contato com o usuário administrador da empresa.',
};

export default PagesConstants;
