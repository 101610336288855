import { Button, Icon, Input, Tooltip } from 'antd';

import React, { useEffect, useRef, useState } from 'react';

export default function InputsDeRegistro({ postAction }) {
  const inputRefSenha = useRef(null);
  const inputRefEmpresa = useRef(null);

  const [loadingFeedback, setloadingFeedback] = useState(false);
  const [focusedSenha, setFocusedSenha] = useState(false);
  const [focusedEmpresa, setFocusedEmpresa] = useState(false);
  const [valorPad, setValorPad] = useState('');
  const [empresaValue, setEmpresaValue] = useState('');
  const [senhaValue, setSenhaValue] = useState('');

  function between(n, a, b) {
    return n >= a && n <= b;
  }

  function onKeyDown(e) {
    const keyCode = between(e.keyCode, 96, 105) ? e.keyCode - 48 : e.keyCode;

    if (!e.isTrusted && between(keyCode, 48, 57)) {
      setValorPad(String.fromCharCode(keyCode));
    }

    if (e.keyCode === 192) {
      setValorPad(String.fromCharCode(keyCode));
    }
  }

  function onKeyUpEmpresaRef(e) {
    if (focusedEmpresa === true && e.keyCode === 13) {
      inputRefSenha.current.focus();
    }
  }

  function onKeyUpPinRef(e) {
    if (focusedSenha === true && e.keyCode === 13) {
      salvarPonto();
    }
  }

  function onFocusChange() {
    //O "À" é referente a tecla Backspace.
    if (focusedSenha) {
      if (valorPad !== 'À') {
        if (senhaValue.length <= 5) setSenhaValue(senhaValue + valorPad);
      } else if (valorPad === 'À' && senhaValue !== '') {
        const str = senhaValue;

        setSenhaValue(str.slice(0, str.length - 1));
      }
    } else if (focusedEmpresa) {
      if (valorPad !== 'À') {
        if (empresaValue.length <= 3) setEmpresaValue(empresaValue + valorPad);
      } else if (valorPad === 'À' && empresaValue !== '') {
        const str = empresaValue;

        setEmpresaValue(str.slice(0, str.length - 1));
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return function cleanup() {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  useEffect(() => {
    inputRefEmpresa.current.input.addEventListener('keyup', onKeyUpEmpresaRef);

    return function cleanup() {
      inputRefEmpresa.current.input.removeEventListener('keyup', onKeyUpEmpresaRef);
    };
  }, [focusedEmpresa]);

  useEffect(() => {
    inputRefSenha.current.input.addEventListener('keyup', onKeyUpPinRef);

    return function cleanup() {
      inputRefSenha.current.input.removeEventListener('keyup', onKeyUpPinRef);
    };
  }, [focusedSenha, senhaValue]);

  useEffect(() => {
    if (inputRefEmpresa) inputRefEmpresa.current.input.focus();
  }, [inputRefEmpresa]);

  useEffect(() => {
    onFocusChange();

    return function cleanup() {
      setValorPad('');
    };
  }, [focusedEmpresa, focusedSenha, valorPad]);

  function salvarPonto() {
    const dados = {
      codigoEmpresa: empresaValue !== '' ? empresaValue : '0',
      codigoEmpregado: senhaValue !== '' ? senhaValue : '0',
    };

    postAction(dados, setloadingFeedback).then(() => {
      setSenhaValue('');
    });
  }

  return (
    <div className="flex-coletor-column flex-align-center">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '30px',
        }}>
        <span className="typography-label-input">PIN da Empresa:</span>
        <Input
          ref={inputRefEmpresa}
          value={empresaValue}
          maxLength={6}
          addonAfter={
            <Tooltip title="O PIN da empresa está disponível para o Gestor no portal do Tron Connect , através da opção de configuração de batida remota.">
              <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>
          }
          onFocus={() => {
            setFocusedEmpresa(true);
            setFocusedSenha(false);
          }}
          onChange={e => setEmpresaValue(e.target.value)}
        />

        <span className="typography-label-input">PIN do Colaborador:</span>
        <div className="content-input-senha" id="icon-empregado">
          <Input.Password
            ref={inputRefSenha}
            value={senhaValue}
            maxLength={6}
            addonAfter={
              <Tooltip title="O PIN do colaborador está disponível para o Gestor no portal do Tron Connect , através da opção de configuração de batida remota.">
                <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            }
            onFocus={() => {
              setFocusedSenha(true);
              setFocusedEmpresa(false);
            }}
            onChange={e => setSenhaValue(e.target.value)}
          />
        </div>
      </div>
      <Button
        onClick={salvarPonto}
        style={{
          width: '144px',
          height: '55px',
          fontWeight: 'bold',
          fontSize: '16px',
        }}
        type="primary">
        {loadingFeedback === true ? (
          <Icon style={{ fontSize: 35 }} type="loading" />
        ) : (
          'Registrar Ponto'
        )}
      </Button>
    </div>
  );
}
