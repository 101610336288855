import axios from 'axios';
import Route from 'route-parser';

// eslint-disable-next-line import/no-cycle
import store from '../store';
import configureHeaders from '../util/configureHeaders';
import removeBaseURL from '../util/removeBaseURL';
import sentinelaFront from '../util/sentinelaFront';


const baseURL = process.env.REACT_APP_API_BASE_URL;

const tronconnect = () => {
  const defaultOptions = {
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use((config) => {
    const {
      rota: { rotas },
    } = store.getState();

    const rotasNegadas = rotas.filter(rota => !rota.permitido);
    const rotaExiste = rotasNegadas.some(rota =>
      new Route(rota.path).match(removeBaseURL(config.url))
    );

    // if (rotaExiste) throw new Error('Access Denied');

    return configureHeaders(config);
  });

  instance.interceptors.response.use(
    response => response,
    (err) => {
      if (err.response.status === 401) {
        sessionStorage.clear();

        const { origin } = window.location;
        const redirectURL = `redirectURL=${origin}&toLocation=/`;

        return window.location.assign(
          `${sentinelaFront[process.env.NODE_ENV]}/#/login?${redirectURL}&fromApplication=4`
        );
      }

      return Promise.reject(err);
    }
  );

  return instance;
};

export default tronconnect();
