import { loadMessages, locale } from 'devextreme/localization';
import ptMessages from 'devextreme/localization/messages/pt.json';

import 'moment/locale/pt-br';
import React from 'react';

import ReactDOM from 'react-dom';

import App from './App';
import './theme/assets/dependencies';

loadMessages(ptMessages);
locale(navigator.language);


ReactDOM.render(<App />, document.getElementById('root'));

