import { Result, Button } from 'antd';
import React, { Fragment } from 'react';

import Header from '../../theme/header';

const NotFound = () => (
  <Fragment>
    <Header title="Página não encontrada" />
    <div className="wrapper wrapper-content">
      <Result
        status="404"
        title="404"
        subTitle="A página solicitada não foi encontrada."
        extra={
          <Button type="primary" href={'#/'}>
            Ir para Home
          </Button>
        }
      />
    </div>
  </Fragment>
);

export default NotFound;
