export default config => {
  const token = sessionStorage.getItem('token');
  const tenant = sessionStorage.getItem('tenant');

  if (token && token !== '') config.headers.Authorization = `Bearer ${token}`;
  if (tenant && tenant !== '') config.headers.ServiceIdentify = tenant;
  config.data = config.data || {};

  return config;
};
