import styled from 'tachyons-components';

export const Section = styled('div')`
flex flex-wrap content-center justify-center w-100 h-100 bg-blue`;

export const Article = styled('div')`
w-25 ma2 h4 items-center justify-center flex flex-column flex-wrap`;

export const Prop = styled('h3')`
f5 f4-ns mb0 white`;

export const Title = styled('h1')`
f4 f3-ns white w-100 tc`;

export const timeZones = {
  'America/Sao_Paulo': -3,
  'America/Bahia': -3,
  'America/Manaus': -4,
  'America/Rio_Branco': -5,
  'America/Noronha': -2,
};

export const formatStringToDDMMYY = (stringDate) => {
  if (stringDate) {
    const date = stringDate.toString();
    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);
    const hour = date.substring(8, 10);
    const minute = date.substring(10, 12);

    return `${day  }/${  month  }/${  year  } ${  hour  }:${  minute}`;
  }
  return '';
};
