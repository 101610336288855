import * as a from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoIntegracao: false,
  dataSource: [],
};

export default (state = INITIAL_STATE, action = 0) => {
  const { type, payload } = action;

  switch (type) {
    case a.INT_SET_ESTADO_INICIAL:
      return { ...state };
    case a.INT_SET_EVENTO:
      return { ...state, dataSource: payload };
    case a.INT_PROCESSANDO_INTEGRACAO:
      return { ...state, processandoIntegracao: payload };
    default:
      return { ...state };
  }
};
