import moment from 'moment';

import {
  PON_PROCESSANDO_PONTUALIDADE,
  PON_SET_DATA,
  PON_SET_ESTADO_INICIAL,
  PON_SET_MODAL_VISIBLE,
  PON_SET_PONTUALIDADE,
  PON_SET_PRESENCA,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoPontualidade: false,
  modalVisible: false,
  data: moment().format('YYYYMMDD'),
  dadosPresenca: {
    pontualidades: [],
    presentes: [],
    ausentes: [],
    ferias: [],
    afastados: [],
    licenca: [],
    compensado: [],
    foraJornada: [],
  },
  dadosPontualidade: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PON_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoPontualidade: false,
        modalVisible: false,
        data: moment().format('YYYYMMDD'),
        dadosPresenca: {
          pontualidades: [],
          presentes: [],
          ausentes: [],
          ferias: [],
          afastados: [],
          licenca: [],
          compensado: [],
          foraJornada: [],
        },
        dadosPontualidade: [],
      };
    case PON_SET_PRESENCA:
      return { ...state, dadosPresenca: payload };
    case PON_SET_PONTUALIDADE:
      return { ...state, dadosPontualidade: payload };
    case PON_PROCESSANDO_PONTUALIDADE:
      return { ...state, processandoPontualidade: payload };
    case PON_SET_MODAL_VISIBLE:
      return { ...state, modalVisible: payload };
    case PON_SET_DATA:
      return { ...state, data: payload };
    default:
      return { ...state };
  }
};
