import { format, getYear } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useEffect, useState } from 'react';

export default function RelogioDigital() {
  const semana = [
    'Domingo',
    'Segunda - Feira',
    'Terça - Feira',
    'Quarta - Feira',
    'Quinta - Feira',
    'Sexta - Feira',
    'Sábado',
  ];

  const Timer = () => {
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
      const interval = setInterval(() => {
        setSeconds(seconds + 1);
      }, 1000);
      return () => clearInterval(interval);
    });
    return format(new Date(), 'HH:mm:ss', new Date());
  };

  return (
    <div
      className="flex-coletor-column flex-align-center"
      style={{
        justifyContent: 'space-around',
      }}>
      <div className="text-center">
        <div style={{ flex: 1 }}>
          <span className="typography-timer">{Timer()}</span>
        </div>
      </div>
      <div className="text-center">
        <div className="flex-coletor-column" style={{ marginBottom: '20px' }}>
          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
            {`${new Date().getDate()} ` +
              `de` +
              ` ${format(new Date(), 'MMMM', { locale: ptBR })
                .charAt(0)
                .toUpperCase()}${format(new Date(), 'MMMM', { locale: ptBR }).slice(1)} ` +
              `de` +
              ` ${getYear(new Date())}`}
          </span>
          <span className="typography-day">
            {semana[new Date().getDay()]}
          </span>
        </div>
      </div>
    </div>
  );
}
