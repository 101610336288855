import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import AfastamentoReducer from './afastamentoReducer';
import AvisoFeriasReducer from './avisoFeriasReducer';
import AvisoPrevioReducer from './avisoPrevioReducer';
import candidatoReducer from './candidatoReducer';
import ConfiguracaoBatidaRemotaReducer from './configuracaoBatidaRemotaReducer';
import ConfiguracoesUsuarioReducer from './configuracoesUsuarioReducer';
import ContribuinteIndividualReducer from './contribuinteIndividualReducer';
import DefaultReducer from './defaultReducer';
import EmpregadoReducer from './empregadoReducer';
import EmpresaReducer from './empresaReducer';
import EnumeradorReducer from './enumeradorReducer';
import EventoPontoRecucer from './eventoPontoReducer';
import FichaFinanceiraReducer from './fichaFinanceiraReducer';
import FolhaPontoReducer from './folhaPontoReducer';
import FrequenciaReducer from './frequenciaReducer';
import GestaoFrequenciaReducer from './gestaoFrequenciaReducer';
import GestorReducer from './gestorReducer';
import IndicadoresReducer from './indicadoresReducer';
import InformeRendimentosReducer from './informeRendimentosReducer';
import IntegracaoReducer from './integracoesReducer';
import JustificativaFaltaReducer from './justificativaFaltaReducer';
import LancamentoFaixaSalarialReducer from './lancamentoFaixaSalarialReducer';
import LiquidoFolhaReducer from './liquidoFolhaReducer';
import MapaFolhaReducer from './mapaFolhaReducer';
import MotivoReducer from './motivoReducer';
import PDFViewerReducer from './pdfViewerReducer';
import PontualidadeReducer from './pontualidadeReducer';
import ProgramacaoEventosReducer from './programacaoEventosReducer';
import ProgramacaoFeriasReducer from './programacaoFeriasReducer';
import ReciboReducer from './reciboReducer';
import RegistroPontoReducer from './registroPontoReducer';
import ResumoFolhaReducer from './resumoFolhaReducer';
import RotaReducer from './rotasReducer';
import UsuarioReducer from './usuarioReducer';

const rootReducer = combineReducers({
  afastamento: AfastamentoReducer,
  avisoFerias: AvisoFeriasReducer,
  avisoPrevio: AvisoPrevioReducer,
  configuracoesUsuario: ConfiguracoesUsuarioReducer,
  configuracaoBatidaRemota: ConfiguracaoBatidaRemotaReducer,
  contribuinteIndividual: ContribuinteIndividualReducer,
  default: DefaultReducer,
  empregado: EmpregadoReducer,
  empresa: EmpresaReducer,
  enumerador: EnumeradorReducer,
  eventoPonto: EventoPontoRecucer,
  fichaFinanceira: FichaFinanceiraReducer,
  folhaPonto: FolhaPontoReducer,
  form: reduxFormReducer,
  frequencia: FrequenciaReducer,
  gestor: GestorReducer,
  gestaoFrequencia: GestaoFrequenciaReducer,
  integracao: IntegracaoReducer,
  indicadores: IndicadoresReducer,
  informeRendimentos: InformeRendimentosReducer,
  justificativaFalta: JustificativaFaltaReducer,
  liquidoFolha: LiquidoFolhaReducer,
  mapaFolha: MapaFolhaReducer,
  motivo: MotivoReducer,
  pdf: PDFViewerReducer,
  pontualidade: PontualidadeReducer,
  programacaoEventos: ProgramacaoEventosReducer,
  programacaoFerias: ProgramacaoFeriasReducer,
  recibo: ReciboReducer,
  registroPonto: RegistroPontoReducer,
  resumoFolha: ResumoFolhaReducer,
  rota: RotaReducer,
  toastr: toastrReducer,
  usuario: UsuarioReducer,
  lancamentoFaixaSalarial: LancamentoFaixaSalarialReducer,
  candidato: candidatoReducer,
});

export default rootReducer;
