import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Navigation from './navigation';

class Index extends Component {
  render() {
    if (!sessionStorage.getItem('token') || !sessionStorage.getItem('tenant')) return null;
    return <Navigation />;
  }
}

const mapStateToProps = state => ({ usuario: state.usuario });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Index);
