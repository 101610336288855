import { Drawer } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import List from 'devextreme-react/list';
import { buscarEmpresas, trocarEmpresa } from '../../actions/defaultActions';

export class Form extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.buscarEmpresas();
  }

  itemRender({ nome, id }) {
    return (
      <div key={id} className="tron-primary">
        <div className="row">
          <div
            className="col-md-10"
            style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
            <a>
              <span>{nome}</span>
            </a>
          </div>
          <div>
            <a>
              <i className="fa fa-sign-in fa-2x" />
            </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { visible, onCancel, trocarEmpresa } = this.props;

    return (
      <Drawer
        title="Seleção de Empresas"
        placement="right"
        closable={false}
        width={500}
        onClose={onCancel}
        visible={visible}>
        <List
          dataSource={this.props.default.empresas}
          itemRender={this.itemRender}
          searchExpr={'nome'}
          searchEnabled={true}
          onItemClick={({ itemData }) => trocarEmpresa(itemData.id)}
        />
      </Drawer>
    );
  }
}

const mapStateToProps = state => ({ default: state.default });

const mapDispatchToProps = { buscarEmpresas, trocarEmpresa };

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Form);
