import {
  IND_PROCESSANDO_INDICADORES,
  IND_SET_AFASTADOS,
  IND_SET_ESTADO_INICIAL,
  IND_SET_HEADCOUNT,
  IND_SET_MODAL,
  IND_SET_SENIORIDADE,
  IND_SET_AVISOS,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoIndicadores: false,
  dadosHeadcount: [],
  dadosSenioridade: [],
  afastados: 0,
  avisos: 0,
  modal: {
    title: '',
    visible: false,
    dataSource: [],
    type: 0,
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case IND_SET_ESTADO_INICIAL:
      return {
        ...state,
        dadosHeadcount: [],
        dadosSenioridade: [],
        processandoIndicadores: false,
        afastados: 0,
        avisos: 0,
        modal: {
          title: '',
          visible: false,
          dataSource: [],
          type: 0,
        },
      };
    case IND_PROCESSANDO_INDICADORES:
      return { ...state, processandoIndicadores: payload };
    case IND_SET_HEADCOUNT:
      return { ...state, dadosHeadcount: payload };
    case IND_SET_SENIORIDADE:
      return { ...state, dadosSenioridade: payload };
    case IND_SET_MODAL:
      return { ...state, modal: payload };
    case IND_SET_AFASTADOS:
      return { ...state, afastados: payload };
    case IND_SET_AVISOS:
      return { ...state, avisos: payload };
    default:
      return { ...state };
  }
};
