import {
  DFT_SET_CARREGANDO_APLICACAO,
  DFT_SET_EMPRESAS,
  DFT_SET_ESTADO_INICIAL,
  DFT_SET_MODAL_VISIBLE,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  modalVisible: false,
  empresas: [],
  carregandoAplicacao: true,
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case DFT_SET_ESTADO_INICIAL:
      return { ...state, empresas: [], modalVisible: false };
    case DFT_SET_MODAL_VISIBLE:
      return { ...state, modalVisible: payload };
    case DFT_SET_EMPRESAS:
      return { ...state, empresas: payload };
    case DFT_SET_CARREGANDO_APLICACAO:
      return { ...state, carregandoAplicacao: payload };
    default:
      return { ...state };
  }
};
