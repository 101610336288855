import {
  CFG_ESTADO_INICIAL_SUCESSO,
  CFG_CONFIGURACAO_NOTIFICACAO_SUCESSO,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  configuracoesNotificacao: {
    notificaoAplicativo: {
      notificarNovoReciboPagamento: false,
      notificarAtualizacaoReciboPagamento: false,
      notificarNovoInformeRendimentos: false,
      notificarAtualizacaoInformeRendimentos: false,
      notificarFolhaDePonto: false,
      notificarAtualizacaoFolhaDePonto: false,

    },
    notificacaoEmail: {
      notificarNovoReciboPagamento: false,
      notificarAtualizacaoReciboPagamento: false,
      notificarNovoInformeRendimentos: false,
      notificarAtualizacaoInformeRendimentos: false,
      notificarFolhaDePonto: false,
      notificarAtualizacaoFolhaDePonto: false,

    },
    notificacaoGestor: {
      notificarJustificativa: false,
      ocultarInativos: false,
    },
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CFG_ESTADO_INICIAL_SUCESSO:
      return { ...INITIAL_STATE };
    case CFG_CONFIGURACAO_NOTIFICACAO_SUCESSO:
      return {
        ...state,
        configuracoesNotificacao: payload || { ...INITIAL_STATE.configuracoesNotificacao },
      };
    default:
      return { ...state };
  }
};
