import * as a from '../actionTypes/actionTypes'

const INITIAL_STATE = {
    processandoRecibo: false,
    recibos: []
}

export default ( state = INITIAL_STATE, action ) =>{

    const { type, payload } = action

    switch (type) {
        case a.REC_SET_ESTADO_INICIAL:
            return { ...state, processandoRecibo: false, recibos: [] }
        case a.REC_GET_RECIBOS:
            return { ...state, recibos: payload }
        case a.REC_PROCESSANDO_RECIBOS:
            return { ...state, processandoRecibo: payload }
        default:
            return { ...state };
    }
}
