import * as a from './../actionTypes/actionTypes'

const INITIAL_STATE = {
    processandoContribuinte: false,
    contribuintes: []
}

export default (state = INITIAL_STATE, action) => {

    const { type, payload } = action

    switch (type) {
        case a.CIL_SET_ESTADO_INICIAL:
            return { ...state, processandoContribuinte: false, contribuintes: [] }
        case a.CIL_SET_CONTRIBUINTES:
            return { ...state, contribuintes: payload }
        case a.CIL_PROCESSANDO_CONTRIBUINTE:
            return { ...state, processandoContribuinte: payload }
        default:
            return { ...state };
    }
}
