import {
  GES_PROCESSANDO_GESTOR,
  GES_SET_ESTADO_INICIAL,
  GES_SET_GESTORES,
  GES_SET_MODAL_VISIBLE,
  GES_SET_PESSOAS,
  GES_SET_PESSOAS_SELECIONADAS,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoGestor: false,
  visible: false,
  gestores: [],
  pessoas: [],
  pessoasEquipe: [],
  targetKeys: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GES_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoGestor: false,
        visible: false,
        gestores: [],
        pessoas: [],
        pessoasEquipe: [],
        targetKeys: [],
      };
    case GES_SET_GESTORES:
      return { ...state, gestores: payload };
    case GES_SET_PESSOAS:
      return { ...state, pessoas: payload.pessoas, pessoasEquipe: payload.pessoasEquipe };
    case GES_PROCESSANDO_GESTOR:
      return { ...state, processandoGestor: payload };
    case GES_SET_PESSOAS_SELECIONADAS:
      return { ...state, targetKeys: payload };
    case GES_SET_MODAL_VISIBLE:
      return { ...state, visible: payload };
    default:
      return { ...state };
  }
};
