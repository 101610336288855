// eslint-disable-next-line import/no-useless-path-segments
import * as a from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processandoLancamentoFaixaSalarial: false,
  lancamentosFaixasSalariais: [],
};

export default (state = INITIAL_STATE, action = undefined) => {
  const { type, payload } = action;

  switch (type) {
    case a.LFS_SET_ESTADO_INICIAL:
      return {
        ...state,
        processandoLancamentoFaixaSalarial: false,
        lancamentosFaixasSalariais: [],
      };
    case a.LFS_GET_LANCAMENTOS_FAIXAS_SALARIAIS:
      return { ...state, lancamentosFaixasSalariais: payload };
    case a.LFS_PROCESSANDO_LANCAMENTO_FAIXA_SALARIAL:
      return { ...state, processandoLancamentoFaixaSalarial: payload };
    default:
      return { ...state };
  }
};
