import '../config/reactotron';
import { applyMiddleware, createStore, compose } from 'redux';
import multi from 'redux-multi';
import promise from 'redux-promise';
import thunk from 'redux-thunk';

import reducers from '../reducers/reducers';

const middlewares = [thunk, multi, promise];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancers =
  process.env.NODE_ENV === 'local'
    ? composeEnhancers(applyMiddleware(...middlewares), console.tron.createEnhancer())
    : composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(reducers, enhancers);

export default store;
