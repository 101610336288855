import axios from 'axios';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { change } from 'redux-form';

import {
  DFT_SET_CARREGANDO_APLICACAO,
  DFT_SET_EMPRESAS,
  DFT_SET_MODAL_VISIBLE
} from '../actionTypes/actionTypes';
import sentinela from '../api/sentinela';


export const carregaAutenticacao = async (token, tenant) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('tenant', tenant);
};

export const logOutUser = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('tenant');
};

export const setCarregandoAplicacao = data => ({
  type: DFT_SET_CARREGANDO_APLICACAO,
  payload: data,
});

export const showModal = () => ({
  type: DFT_SET_MODAL_VISIBLE,
  payload: true,
});

export const closeModal = () => ({
  type: DFT_SET_MODAL_VISIBLE,
  payload: false,
});

export const setEmpresas = response => ({
  type: DFT_SET_EMPRESAS,
  payload: response,
});

export const getDadosCep = () => (dispatch, getState) => {
  const { form } = getState();

  const { cep } = form.empregadoForm.values.dadosPessoais;

  if (cep && cep.length > 7) {
    const cepSemMascara = cep.replace(/[^0-98]/g, '');

    axios
      .get(`https://viacep.com.br/ws/${cepSemMascara}/json/`)
      .then((response) => {
        if (response.data.erro) {
          toastr.error('Parâmetro', 'Não foi possível encontrar o endereço pelo CEP informado.');
        } else {
          dispatch([
            change('empregadoForm', 'dadosPessoais.logradouro', response.data.logradouro),
            change('empregadoForm', 'dadosPessoais.bairro', response.data.bairro),
            change('empregadoForm', 'dadosPessoais.cidade', response.data.ibge),
          ]);
        }
      })
      .catch(() =>
        toastr.error('Parâmetro', 'Não foi possível encontrar o endereço pelo CEP informado.')
      );
  }
};

export const buscarEmpresas = () => async (dispatch, getState) => {
  const { usuario } = getState();

  try {
    const { data } = await sentinela.get(`/usuarios/${usuario.pessoaLogada.usuarioId}/tenants`);

    dispatch(
      setEmpresas(
        data
          ? _.orderBy(
              _.filter(data.tenants, tenant => tenant.modulo === 4),
              'nome'
            )
          : []
      )
    );
  } catch (error) {
    dispatch(toastr.error('Empresas', 'Ocorreu um erro ao carregar as Empresas!'));
  }
};

export const trocarEmpresa = id => (dispatch) => {
  const token = sessionStorage.getItem('token');

  dispatch([
    setCarregandoAplicacao(true),
    logOutUser(),
    carregaAutenticacao(token, id),
    window.location.reload(),
  ]);
};
