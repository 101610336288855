import { ROT_SET_MENUS, ROT_SET_ROTAS_PERMITIDAS } from '../actionTypes/actionTypes';
import sentinela from '../api/sentinela';
import routeComponents from '../util/routeComponents';
import { setCarregandoAplicacao } from './defaultActions';

const ROTAS = {
  FREQUENCIAS: '/frequencias',
  DASHBOARDS_PONTUALIDADE: '/dashboards/pontualidade',
  ACESSO_NAO_AUTORIZADO: '/acesso-nao-autorizado',
  RECIBOS: '/recibos',
};

const ROTAS_DEFAULT = [
  {
    path: ROTAS.ACESSO_NAO_AUTORIZADO,
    component: routeComponents[ROTAS.ACESSO_NAO_AUTORIZADO],
    permitido: true,
    exact: true,
  },
];

export const setRotasPermitidas = rotas => ({
  type: ROT_SET_ROTAS_PERMITIDAS,
  payload: rotas,
});

export const setMenus = menus => ({
  type: ROT_SET_MENUS,
  payload: menus,
});

export const getRotasPermitidas = () => async (dispatch, getState) => {
  try {
    const {
      usuario: { pessoaLogada },
    } = getState();

    const { data } = await sentinela.get('/rotas/rotas-permitidas/4');

    const listaRotas = data.rotas.map(rota => ({
      path: rota.path,
      component: routeComponents[rota.path],
      permitido: pessoaLogada.status ? rota.permitido : false,
      exact: true,
    }));

    const getRouteComponentIfAllowed = path =>
      listaRotas.find(rota => rota.path === path)?.permitido ? routeComponents[path] : undefined;

    const getComponentOfFirstAllowedRoute = () =>
      routeComponents[
        listaRotas.find(rota => rota.permitido)?.path || ROTAS.ACESSO_NAO_AUTORIZADO
      ];

    const rotaIndex = [];

    if (pessoaLogada.tipo) {
      let componentIndex = getRouteComponentIfAllowed(ROTAS.DASHBOARDS_PONTUALIDADE);

      if (pessoaLogada.tipo === 'Colaborador')
        componentIndex = getRouteComponentIfAllowed(ROTAS.FREQUENCIAS);
      else if (pessoaLogada.tipo === 'Contribuinte Individual')
        componentIndex = getRouteComponentIfAllowed(ROTAS.RECIBOS);

      componentIndex = componentIndex || getComponentOfFirstAllowedRoute();

      rotaIndex.push({
        path: '/',
        component: componentIndex,
        permitido: pessoaLogada.status,
        exact: true,
      });
    }

    dispatch([
      setRotasPermitidas([...ROTAS_DEFAULT, ...rotaIndex, ...listaRotas]),
      setMenus(data.menus),
      setCarregandoAplicacao(false),
    ]);
  } catch (err) {
    dispatch(setCarregandoAplicacao(false));
  }
};
