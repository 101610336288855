import {
  CBR_PROCESSANDO,
  CBR_SET_COLABORADORES,
  CBR_SET_EMPREGADOS_ATIVOS,
  CBR_SET_EMPREGADOS_CONFIGURADOS,
  CBR_SET_EMPREGADOS_CONFIGURADOS_RECONHECIMENTO_FACIAL,
  CBR_SET_ESTADO_INICIAL,
  CBR_SET_FILTRO,
  CBR_SET_PLANO_CONTRATADO,
  CBR_SET_RECONHECIMENTO_FACIAL,
  GFR_SET_DISPOSITIVO_PADRAO,
  CBR_SET_DADOS_EMPRESA,
} from '../actionTypes/actionTypes';

const INITIAL_STATE = {
  processando: false,
  configuracoes: [],
  empregadosAtivos: 0,
  planoContratado: 0,
  empregadosReconhecimentoFacial: 0,
  empregadosConfigurados: 0,
  empregadosConfiguradosReconhecimentoFacial: 0,
  dispositivoPadrao: {},
  filtro: [],
  dadosEmpresa: {},
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CBR_SET_ESTADO_INICIAL:
      return {
        ...state,
        processando: false,
        empregadosAtivos: 0,
        configuracoes: [],
        empregadosConfigurados: 0,
        empregadosConfiguradosReconhecimentoFacial: 0,
        dadosEmpresa: {},
      };
    case CBR_PROCESSANDO:
      return { ...state, processando: payload };
    case CBR_SET_COLABORADORES:
      return { ...state, configuracoes: payload };
    case CBR_SET_FILTRO:
      return { ...state, filtro: payload };
    case CBR_SET_EMPREGADOS_ATIVOS:
      return { ...state, empregadosAtivos: payload };
    case CBR_SET_PLANO_CONTRATADO:
      return { ...state, planoContratado: payload };
    case CBR_SET_EMPREGADOS_CONFIGURADOS:
      return { ...state, empregadosConfigurados: payload };
    case CBR_SET_RECONHECIMENTO_FACIAL:
      return { ...state, empregadosReconhecimentoFacial: payload };
    case CBR_SET_EMPREGADOS_CONFIGURADOS_RECONHECIMENTO_FACIAL:
      return { ...state, empregadosConfiguradosReconhecimentoFacial: payload };
    case GFR_SET_DISPOSITIVO_PADRAO:
      return { ...state, dispositivoPadrao: payload };
    case CBR_SET_DADOS_EMPRESA:
      return { ...state, dadosEmpresa: payload };
    case GFR_SET_DISPOSITIVO_PADRAO:
      return { ...state, dispositivoPadrao: payload };
    default:
      return { ...state };
  }
};
