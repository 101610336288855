import * as a from './../actionTypes/actionTypes'

const INITIAL_STATE = {
    processandoAvisoPrevio: false,
    avisosPrevios: [],
    disabledReducao: true,
    dataAvisoRequired: false
}

export default (state = INITIAL_STATE, action) => {

    const { type, payload } = action

    switch (type) {
        case a.AVP_SET_ESTADO_INICIAL:
            return { ...state, processandoAvisoPrevio: false, avisosPrevios: [], disabledReducao: true, dataAvisoRequired: false }
        case a.AVP_GET_AVISOS_PREVIOS:
            return { ...state, avisosPrevios: payload }
        case a.AVP_PROCESSANDO_AVISO_PREVIO:
            return { ...state, processandoAvisoPrevio: payload }
        case a.AVP_SET_STATE_REDUCAO:
            return { ...state, disabledReducao: payload }
        case a.AVP_SET_REQUIRED_DATA_AVISO:
            return { ...state, dataAvisoRequired: payload }
        default:
            return { ...state };
    }
}
