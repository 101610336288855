import React from 'react';

import applestore from './assets/images/footer/applestore.png';
import playstore from './assets/images/footer/playstore.png';

const Footer = ({ fullPage }) => (
  <div className={`footer ${fullPage ? 'fixed_full' : 'fixed'}`}>
    <div className="pull-right">
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="mr-1"
        href="https://play.google.com/store/apps/details?id=br.com.tron.TronConnectApp&hl=pt_BR">
        <img alt="play" src={playstore} />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="mr-1"
        href="https://apps.apple.com/br/app/tron-connect/id1445008662">
        <img alt="apple" src={applestore} />
      </a>
    </div>
    <div>
      <label>Copyright</label> Tron Informática &copy; {new Date().getFullYear()}
    </div>
  </div>
);

export default Footer;
